<script>
import config from "@/helpers/config"
export default {
    created() {  
        this.$cache.createCache('publicPage', 'public-page/', {id: this.$route.params.id });      
       
    },
    computed: {
        url() { return config.urlToServer },  
        publicPage() { return this.$store.state.publicPage != null ?  this.$store.state.publicPage.publicPage : null },
        boardMembers() { return this.$store.state.publicPage != null ?  this.$store.state.publicPage.boardMembers : {} },
        workGroups() { return this.$store.state.publicPage != null ?  this.$store.state.publicPage.workGroups : {} },
        statutes() { return this.$store.state.publicPage != null ?  this.$store.state.publicPage.statutes : {} },
        rulesOfProcedure() { return this.$store.state.publicPage != null ?  this.$store.state.publicPage.rulesOfProcedure : {} },
        publicPageCommunity() { return this.$store.state.publicPage != null ?  this.$store.state.publicPage.publicPageCommunity : {} },
        height() { return window.innerHeight/1.3 + 'px' },
    }
}
</script>
<template>
   <section class="public-page" v-if="publicPage != null" v-fade-in>
        <div class="top-public" :style="{ 'background-image': publicPageCommunity.community_image_name != null ? 'url(' + url + 'uploads/'+ publicPageCommunity.customer_id+'/images/'+ publicPageCommunity.community_image_name+ ')' : '', 'height': height }">
        
        </div>
        <div class="text-section" v-html="this.$adaptHtml.adaptHtml(publicPageCommunity.community_text, publicPage.header_color)"></div>
       <div class="text-section">
            <div class="Community-table" v-if="publicPageCommunity.show_board_members" v-fade-in>
                <h2 :style="{ 'color': publicPage.header_color }">Styrelsen</h2>
                <table>
                    <tr v-for="member in boardMembers" class="table-style">                       
                    <td> {{member.board_member_type}}</td>
                    <td> {{member.user_name}} ({{ member.election_period }})</td>
                    </tr>
                </table>
            </div>
            
            <div class="Community-table" v-if="publicPageCommunity.show_work_groups" v-fade-in>
                <h2 :style="{ 'color': publicPage.header_color }">Arbetsgrupper</h2>
                <table>
                    <tr v-for="group in workGroups" class="table-style">                       
                    <td> {{ group.name }}</td>
                    <td> Ansvariga: {{ group.members.map(x => x.user.full_name).join(", ") }}</td>
                    </tr>
                </table>
            </div>
            
            <div class="Community-table" v-if="publicPageCommunity.show_statutes" v-fade-in>
                <h2 :style="{ 'color': publicPage.header_color }">Stadgar</h2>
                <table>
                    <tr v-for="statute in statutes" class="table-style">                       
                        <td>
                            <span v-html="statute.statute"></span>
                        </td>                        
                    </tr>
                </table>
            </div>
            
            <div class="Community-table" v-if="publicPageCommunity.show_rules_of_procedure" v-fade-in>
                <h2 :style="{ 'color': publicPage.header_color }">Ordningsregler</h2>
                <table>
                    <tr v-for="rule_of_procedure in rulesOfProcedure" class="table-style">                       
                        <td>
                            <span v-html="rule_of_procedure.rule_of_procedure"></span>
                        </td>                        
                    </tr>
                </table>
            </div>
       </div>
   </section>
</template>
<style scoped>
    .Community-table
    {
        max-width: 1300px;
        margin: auto;
        margin-bottom: 60px;
    }

    p{
        width: 8px;
        height: 8px;
        border-radius: 4px;
        margin: 5px 7px 0px 0px;
        float: left;
    }
</style>