import { createRouter, createWebHistory } from "vue-router";

import Home from '../views/Home.vue'
import Community from '../views/Community.vue'
import Contact from '../views/Contact.vue'
import Tab from '../views/Tab.vue'
import ImageGallery from '../views/ImageGallery.vue'
import Bookings from '../views/Bookings.vue'
import BookingHoursAdd from '../views/BookingHoursAdd.vue'
import BookingDaysAdd from '../views/BookingDaysAdd.vue'
import BookingDetails from '../views/BookingDetails.vue'

import SaleHome from '../views/SaleSite/SaleHome.vue'
import SaleOrder from '../views/SaleSite/SaleOrder.vue'
import SaleTrial from '../views/SaleSite/SaleTrial.vue'
import SaleQnA from '../views/SaleSite/SaleQnA.vue'
import SaleDemo from '../views/SaleSite/SaleDemo.vue'
import SaleCost from '../views/SaleSite/SaleCost.vue'
import SaleNews from '../views/SaleSite/SaleNews.vue'
import SaleHistory from '../views/SaleSite/SaleHistory.vue'

import NotFound from '../views/NotFound.vue'

const routes = [       
    {
        path: '/',
        component: SaleHome,
        name: 'saleHome'
    },       
    {
        path: '/order/',
        component: SaleOrder,
        name: 'saleOrder'
    },    
    {
        path: '/trial/',
        component: SaleTrial,
        name: 'saleTrial'
    },    
    {
        path: '/demo/',
        component: SaleDemo,
        name: 'saleDemo'
    },    
    {
        path: '/price/',
        component: SaleCost,
        name: 'saleCost'
    },    
    {
        path: '/q-n-a/',
        component: SaleQnA,
        name: 'saleQnA'
    },    
    {
        path: '/news/',
        component: SaleNews,
        name: 'saleNews'
    },    
    {
        path: '/history/',
        component: SaleHistory,
        name: 'saleHistory'
    },    
    {
        path: '/b/:id',
        component: BookingDetails,
        name: 'bookingDetails'
    },    
    {
        path: '/:id/',
        component: Home,
        name: 'home'
    },    
    {
        path: '/:id/community',
        component: Community,
        name: 'community'
    },    
    {
        path: '/:id/image-gallery',
        component: ImageGallery,
        name: 'imageGallery'
    },    
    {
        path: '/:id/contact',
        component: Contact,
        name: 'contact'
    },    
    {
        path: '/:id/tab/:tabid',
        component: Tab,
        name: 'tab'
    },    
    {
        path: '/:id/booking-hours/:bookingid',
        component: BookingHoursAdd,
        name: 'bookingHoursAdd'
    },    
    {
        path: '/:id/booking-days/:bookingid',
        component: BookingDaysAdd,
        name: 'bookingDaysAdd'
    },    
    {
        path: '/:id/bookings',
        component: Bookings,
        name: 'bookings'
    },    
    // {
    //     path: '/:id/booking/:bookingcode',
    //     component: BookingShow,
    //     name: 'bookingShow'
    // },    
    {
        path: '/:pathMatch(.*)*',
        component: NotFound
    }

]
const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        // always scroll to top
        return { top: 0 }
      },

})

export default router