<script>
import Logo from '@/components/Logo.vue'
export default {
    components: { Logo },
    computed: {
        publicPage() { return this.$store.state.publicPage != null ?  this.$store.state.publicPage.publicPage : null },
        customerName() { return this.$store.state.publicPage != null ?  this.$store.state.publicPage.customerName : {} },
        tabs() { return this.$store.state.publicPage != null ?  this.$store.state.publicPage.tabs : {} },
        availableBookings() { return this.$store.state.publicPage != null ?  this.$store.state.publicPage.availableBookings : {} },
        settings() { return this.$store.state.publicPage != null ?  this.$store.state.publicPage.settings : {} },
        hasSettings() { return this.settings },
        selectedPage() { return this.$route.name }
    },
    data() {
        return {      
          selectedTab: ''
        }
    },
    methods:
    {
        goTopage(page, bookingItem)
        {
            $("body").scrollTop(0);   
            if(page == 'bookingHoursAdd' || page == 'bookingDaysAdd')
            {
                this.$router.push({ name: page, params: { id: this.$route.params.id, bookingid: bookingItem.id } })
            }
            else
            {
                this.$router.push({ name: page, params: { id: this.$route.params.id } })
            }
        },
        goToTab(id)
        {
            this.$router.push({ name: 'tab', params: { id: this.$route.params.id, tabid: id } })
        }
    }
}
</script>
<template>
<div v-if="publicPage != null && selectedPage != 'bookingHoursAdd' && selectedPage != 'bookingDaysAdd'" class="public-page" v-fade-in>
    <div class="public-footer" :style="{ 'background-color': publicPage.background_color }">
        <section>          
            <div>         
                <b>Meny</b> 
                <ul>
                    <li @click="goTopage('home')">
                        Hem     
                    </li>
                    <li @click="goTopage('community')">
                        Föreningen     
                    </li>
                    
                    <li v-for="tab in tabs" @click="goToTab(tab.id)">
                       {{ tab.name }}
                    </li>
                   
                    <li v-if="publicPage.include_image_gallery" @click="goTopage('imageGallery')">
                        Bildgalleriet
                    </li>                     
                    <li v-if="publicPage.include_contact"  @click="goTopage('contact')">
                        Kontakta Oss      
                    </li>                  
                </ul>     
            </div>
            <div v-if="availableBookings.length > 0">
                <b>{{ hasSettings ? settings.BookingItem.plural : ''  }}</b>
                <ul>
                    <li v-for="booking in availableBookings">
                        <span v-if="booking.duration_unit == 'Timmar'" @click="goTopage('bookingHoursAdd', booking)">{{ booking.name }}</span>                        
                        <span v-else @click="goTopage('bookingDaysAdd', booking)">{{ booking.name }}</span>                        
                    </li>
                </ul>
            </div>
            <div class="text-center">            
                <img src="../assets/imgs/new_logo.png" style="opacity: 0.2; width: 130px">
                <br><br>
                &copy; {{ new Date().getFullYear() }} {{ customerName }} 
            </div>
        </section>

        <p class="public-footer-samfallify" :style="{ 'color': publicPage.background_color }"> {{ customerName }} är en del av jamiis&copy; härliga community.</p>                  
    </div>
</div>
</template>


<style scoped>
.public-footer
{
    width:100%;
    filter: brightness(90%);
    padding: 70px 0px;
    position: relative;
}

.public-footer section
{
    max-width: 800px;
    margin: auto;
}

.public-footer div
{
    display: inline-block;
    vertical-align: top;
    width: 33%;
}

.public-footer-samfallify 
{
    position: absolute;
    bottom: 7px;
    right: 7px;
    font-size: 12px;  
    filter: brightness(70%);
}
.public-footer ul
{
    list-style: none;
    padding: 0px;
}

.public-footer li
{
   margin-bottom:10px;
   cursor: pointer;
}

.public-footer i
{
    filter: brightness(110%);
    font-size: 70pt;
    padding: 15px;
}

@media only screen and (max-width: 900px) {
    .public-footer div
    {
        display: block;
        width: 100%;
        text-align: center;
        margin-bottom: 40px;
    }
}
</style>