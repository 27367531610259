<script>
import config from "@/helpers/config"
export default {
    components: { },
    created() {  
        this.$cache.createCache('publicPage', 'public-page/', {id: this.$route.params.id });      
       
    },
    computed: {
        url() { return config.urlToServer },  
        publicPage() { return this.$store.state.publicPage != null ?  this.$store.state.publicPage.publicPage : null },
        height() { return window.innerHeight/1.3 + 'px' }
  }
}
</script>
<template>
   <section  class="public-page" v-if="publicPage != null" v-fade-in>
        <div class="top-public" :style="{ 'background-image': publicPage.contact_image_name != null ? 'url(' + url + 'uploads/'+ publicPage.customer_id+'/images/'+ publicPage.contact_image_name + ')' : '', 'height': height }">
        
        </div>
        <div :style="{ 'background-color': publicPage.background_color }">
            <div class="text-section">
                <h1 :style="{ 'color': publicPage.header_color }">Kontakta oss</h1>
                <div v-html="this.$adaptHtml.adaptHtml(publicPage.contact_text, publicPage.header_color)"></div>
            </div>
        </div>
   </section>
</template>
<style scoped>

</style>