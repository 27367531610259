<script>
import Logo from '@/components/Logo.vue'
export default {
    components: { Logo },
  data() {
    return {  
      showMobileMenu: false
    }
  }, 
  methods:
  {
    goTopage(page, bookingItem)
    {
      this.showMobileMenu = false;
      $("body").scrollTop(0);   
      if(page == 'bookingHoursAdd' || page == 'bookingDaysAdd')
      {
        this.$router.push({ name: page, params: { id: this.$route.params.id, bookingid: bookingItem.id } })
      }
      else
      {
        this.$router.push({ name: page, params: { id: this.$route.params.id } })
      }
    },
    goToTab(id)
    {
      this.showMobileMenu = false;
      $("body").scrollTop(0); 
      this.$router.push({ name: 'tab', params: { id: this.$route.params.id, tabid: id } })
    } 
  },
  watch:
  {
    customerName(newvalue)
    {
      document.title = newvalue;
    }
  },
  computed: {
    publicPage() { return this.$store.state.publicPage != null ?  this.$store.state.publicPage.publicPage : {} },
    availableBookings() { return this.$store.state.publicPage != null ?  this.$store.state.publicPage.availableBookings : {} },
    customerName() { return this.$store.state.publicPage != null ? this.$store.state.publicPage.customerName : '' },
    tabs() { return this.$store.state.publicPage != null ?  this.$store.state.publicPage.tabs : {} },
    settings() { return this.$store.state.publicPage != null ?  this.$store.state.publicPage.settings : {} },
    hasSettings() { return this.settings },
    selectedPage() { return this.$route.name },
    height() { return $(window).height() }
  },
}
</script>
<template>
  <section class="MobileMenu" v-fade-in>
    <div class="MobileMenu-icon">     
      <p @click="showMobileMenu = !showMobileMenu">             
        <hr :class="showMobileMenu ? 'MobileMenu-line-fade-out' : 'MobileMenu-line-fader'">
        <hr :class="showMobileMenu ? 'MobileMenu-line-x' : 'MobileMenu-line-x-straight'">
        <hr :class="showMobileMenu ? 'MobileMenu-line-y' : 'MobileMenu-line-y-straight'">
        <hr :class="showMobileMenu ? 'MobileMenu-line-fade-out' : 'MobileMenu-line-fader'">        
        <b v-if="!showMobileMenu">MENY</b>
      </p>     
    </div>
    <div v-if="showMobileMenu">       
        <div class="MobileMenu-links" style="" :style="{ 'background-color': publicPage.background_color, 'height': height + 'px' }">
          <div class="MobileMenu-item" style="margin-top:50px;" @click="goTopage('home')" :style="{ 'background-color': selectedPage == 'home' ? publicPage.background_color : '' }">
            Hem    
          </div>
          <div class="MobileMenu-item" @click="goTopage('community')" :style="{ 'background-color': selectedPage == 'community' ? publicPage.background_color : '' }">
            Föreningen     
          </div>
          <div class="MobileMenu-item" v-for="tab in tabs" @click="goToTab(tab.id)">
            {{ tab.name }}
          </div>
          <div class="MobileMenu-item" v-if="publicPage.include_image_gallery" @click="goTopage('imageGallery')" :style="{ 'background-color': selectedPage == 'imageGallery' ? publicPage.background_color : '' }">
            Bildgalleriet
          </div>         
          <div :style="{ 'background-color':selectedPage == 'bookingHoursAdd' || selectedPage == 'bookingDaysAdd' ? publicPage.background_color : '' }">
              <div class="MobileMenu-item" v-for="booking in availableBookings"  @click="goTopage(booking.duration_unit == 'Timmar' ? 'bookingHoursAdd' : 'bookingDaysAdd', booking)">             
                {{ booking.name }}
              </div>
          </div>
          <div class="MobileMenu-item" v-if="publicPage.include_contact"  @click="goTopage('contact')" :style="{ 'background-color': selectedPage == 'contact'? publicPage.background_color : '' }">
            Kontakta Oss      
          </div> 
          <div class="MobileMenu-bg">
            <img src="../assets/imgs/new_logo.png" style="opacity: 0.2; width: 130px">
          </div>
        </div>  
        <div class="MobileMenu-triangle" :style="{ 'border-left-color': publicPage.background_color }">
        </div>
      </div>  
  </section>
</template>


<style scoped>
.MobileMenu-icon
{
  overflow: auto; 
  position: absolute; 
  z-index: 100; 
  right: 10px;
}
.MobileMenu-links
{
  position: absolute; 
  width:calc(100% - 140px); 
  z-index: 10; 
  top: 0px;
  padding-bottom:100px;
}
.MobileMenu
{ 
  height: 120px; 
  color: black;
  position: fixed;
  z-index: 999;
  top: 0px;
  left: 0px;
  right: 0px;
}
  .MobileMenu b {
    color: white;
    text-shadow: -1px -1px 0 rgb(0, 0, 0), 1px -1px 0 rgb(0, 0, 0), -1px 1px 0 rgb(0, 0, 0), 1px 1px 0 rgb(0, 0, 0);
  } 
  .MobileMenu p 
  {
    font-size: 11pt;
    font-weight: bold;
    text-align: center;
    margin: 17px 30px;
    width: 45px;
    float: right;
    cursor: pointer;
  }  
  .MobileMenu hr {
    height: 5px;
    width: 40px;
    margin: 2px;
    float: right;
    background-color: white;
    border: 1px solid black;
    border-radius: 3px;
  }  
.MobileMenu-line-fade-out
{
  opacity: 0;
  transition: 0.3s;
}
.MobileMenu-line-x
{
  transform: rotate(135deg);
  background-color: black !important;
  border: 1px solid black !important;
  transition: 0.3s;
}
.MobileMenu-line-y
{
  transform: rotate(45deg);
  transition: 0.3s;
  background-color: black !important;
  border: 1px solid black !important;
  position: absolute;
  top: 28px;
  right: 30px;
}
.MobileMenu-line-y-straight
{
  transform: rotate(0deg);
  transition: 0.3s;
  position: absolute;
  top: 28px;
  right: 30px;
}
.MobileMenu-line-x-straight
{
  transform: rotate(180deg);
  transition: 0.3s;
}
.MobileMenu-line-fader
{
  opacity: 1;
  transition: 0.6s;
}
.MobileMenu-item
{
  font-size: 17pt;
  font-weight: bold;
  text-transform: uppercase;
  text-align: left;
  padding: 10px 40px;
  cursor: pointer;
  filter: brightness(90%);
}
.MobileMenu-triangle {
  width: 0;
  height: 0;
  border-bottom: 1500px solid transparent;
  border-left: 274px solid transparent;
  position: absolute;
  top: 0px;
  right: 0px;
}
.MobileMenu-bg
{
  width:150px;
  height: 150px;
  position:absolute;
  bottom: 140px;
  left: 0px;
  right: 0px;
  margin: auto;

}
</style>
